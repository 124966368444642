import Vue from 'vue/dist/vue.esm.js'
import "vuetify/dist/vuetify.min.css"
import vuetify from "../vty.js"
import TurbolinksAdapter from "vue-turbolinks";

Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {
    new Vue({
        vuetify,
        el: '#sessions',
        data: {
        },
        computed: {
        },
        methods: {
        },
        created() {
        },
        destroyed() {
        },
        async mounted() {
        },
    });

})