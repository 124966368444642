const EVENT_NAME = 'appEvent'
const gtmDataLayer = {
    install: function (Vue, options) {
        const dataLayer = window.dataLayer || []
        Vue.prototype.$gtm = {
            sendError: function(action, label){
                dataLayer.push({
                    event: EVENT_NAME,
                    eventCategory: 'appError',
                    eventAction: action || 'defaultAction',
                    eventLabel: label || 'defaultLabel'
                })
            }
        }
    }
}
export default gtmDataLayer