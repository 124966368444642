import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue'
import Vuetify from 'vuetify'
import "vuetify/dist/vuetify.min.css"
import "@mdi/font/css/materialdesignicons.min.css"
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import GtmDataLayer from './plugins/gtm-datalayer'
import Storage from "vue-ls"
import VueVirtualScroller from 'vue-virtual-scroller'

Vue.use(Vuetify)
Vue.use(TurbolinksAdapter)
Vue.use(Storage)
Vue.use(VueVirtualScroller)
Vue.use(GtmDataLayer)

export default new Vuetify({
    icons: {
        iconfont: 'mdi'
    },
})